.iconDiv {
    top: 10px;
    right: 10px;
    z-index: 1;
}

.textDiv {
    top: 78%;
    left: 10px;
    color: white;
    font-size: 15px;
    font-weight: 700;
    /* line-height: 1.2; */
}

.universeImage {
    width: 200px;
    height: 200px;
    border: 1px solid white;
    border-radius: 10px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.universeContainer {
    height: 100%;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
}

.icon {
    cursor: pointer;
}

.main-heading {
    font-weight: bold;
    font-size: calc(.5rem + 2vw);
}

.alert-message {
    color: white;
    font-weight: bold;
    padding: 15px;
    text-align: center;
    width: 50%;
    margin: 10px auto 0 auto;
}

.universe_card .added {
    display: none;
}

.universe_card.isActive .added {
    display: block;
}

.universe_card.isActive .add {
    display: none;
}