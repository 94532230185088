.icon {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 100%;
    padding: 1px;
}

.story-img {
    border: 2px solid grey;
    border-radius: 50%;
    padding: 2px;
    width: 70px;
    height: 70px;
}

.story-img-active {
    border: 2px solid var(----themeMain);
    border-radius: 50%;
    padding: 2px;
    width: 70px;
    height: 70px;
}

.react-multi-carousel-track {
    right: 100px;
    left: 0;
    margin: 0 10px;
}

.react-multi-carousel-track-profile {
    right: 40px;
    margin: 0 10px;
}

.countries {
    margin-top: 3px;
}

.universes-text {
    font-size: 14px;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70px;
    margin: auto;
    font-weight: 500;
}

.mulitiverse-dropdown {
    width: 95%!important;
}

.btnGrp3 {
    display: flex;
    position: absolute;
    justify-content: space-between;
    top: 310px;
}

.carousel-container-profile {
    box-shadow: 0 0 1.25rem 0 rgb(0 0 0 / 10%);
    border-radius: 5px;
    padding: 10px 8px 10px 5px;
    margin-bottom: 20px;
    background-color: #fff;
    width: 100%;
}

.write-something::placeholder {
    color: black!important;
}

.multiverseSelect {
    width: 100%;
}

.profile-img1 {
    width: 150px;
    height: 150px;
    border: 1px solid;
    border-radius: 100%;
}

.upload-icon {
    border: 1px solid white;
    border-radius: 50%;
    padding: 7px 13px;
    text-align: center;
    margin-right: 10px;
}