.profile-pic {
    width: 100%;
    height: 150px;
    border: 1px solid white;
    border-radius: 50%;
}

.alert-message {
    padding: 15px;
    width: 60%;
    margin: 0 auto 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.cancel-btn {
    background-color: grey!important;
    color: black!important;
    border: 1px solid grey!important;
}