/*---------------------------------------------------------------------
Footer
-----------------------------------------------------------------------*/
.iq-footer {
	padding:0.938rem;
	margin-left: 15.625rem;
	width: calc(100vw - 505px);
	@if $enable-shadows == true {
		@include shadow-mixin($box-shadow);
	}
	
	@if $enable-transitions == true {
		@include transition-mixin(all 0.3s ease-in-out);
	}
	ul {
		li {
			a {
				color:$body-color;
				&:hover {
					color: $primary;
					text-decoration: none;
				}
			}
		}
	}
}
.bg-banner-image.style-three {
	height: 150px;
	// background: url('../images/bg-03.jpg');
}
.iq-mt--70 {
	margin-top: -4.375rem;
}
.banner-main-text {
	position: absolute;
	left: 15%;
	bottom: 13%;
}
@media (min-width: 1300px) and (max-width: 1499px){
	.iq-footer {
		margin-left:5rem;
		width: calc(100vw - 163px);
	}
}
@media (max-width: 1299px){
.iq-footer, body.sidebar-main .iq-footer {
    margin-left: 0;
    width: 100%;}
}
@include media-breakpoint-down(xl) {
    .iq-footer {
        padding: 0.938rem 0.625rem;
        margin-left: 0;
        width: 100%;
    }
    body.sidebar-main {
		.iq-footer {
			padding: 0.938rem 0.625rem;
			margin-left: 0;
			width: 100%;
		}
    }
}
@include media-breakpoint-down(lg) {
    .iq-footer{
        .col-lg-6{
            text-align: center !important;
        }
        .col-lg-6.d-flex.justify-content-end {
             text-align: center !important;
			 justify-content: center !important;
        }
    }  
	.iq-footer span {
		font-size: 0.8rem;
	}
}