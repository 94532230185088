.icon {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 100%;
  padding: 1px;
}

.reset-btn {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font: inherit;
}
.like-comment-btn:hover {
  text-decoration: underline;
}

.story-img {
  border: 2px solid grey;
  border-radius: 50%;
  padding: 2px;
  width: 70px;
  height: 70px;
}

.story-img-active {
  border: 2px solid var(----themeMain);
  border-radius: 50%;
  padding: 2px;
  width: 70px;
  height: 70px;
}

.react-multi-carousel-track {
  right: 100px;
  left: 0;
  margin: 0 10px;
}

.react-multi-carousel-track-profile {
  right: 40px;
  margin: 0 10px;
}

.countries {
  margin-top: 3px;
}

.universes-text {
  font-size: 14px;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70px;
  margin: auto;
  font-weight: 500;
}

.mulitiverse-dropdown {
  width: 95% !important;
}

.btnGrp3 {
  display: flex;
  position: absolute;
  justify-content: space-between;
  top: 310px;
}

.carousel-container-profile {
  box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px 8px 10px 5px;
  margin-bottom: 20px;
  background-color: #fff;
  width: 100%;
}

.write-something::-moz-placeholder {
  color: black !important;
}

.write-something::placeholder {
  color: black !important;
}

.multiverseSelect {
  width: 100%;
}

.profile-img1 {
  width: 150px;
  height: 150px;
  border: 1px solid;
  border-radius: 100%;
}

.upload-icon {
  border: 1px solid white;
  border-radius: 50%;
  padding: 7px 13px;
  text-align: center;
  margin-right: 10px;
} /*# sourceMappingURL=index.css.map */

.skeletor {
  position: relative;
  overflow: hidden;
  background-color: #f0f0f0; /* Placeholder background color */
}

.skeletor::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent 25%,
    rgba(255, 255, 255, 0.5) 50%,
    transparent 75%
  );
  background-size: 200% 100%;
  animation: skeletor-shimmer 2s linear infinite; /* Animation for shimmering effect */
}

@keyframes skeletor-shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
