.overflow-scroll::-webkit-scrollbar {
    display: none;
}

.form-container {
    overflow: unset;
    /* height: 100vh; */
}

.form-container::-webkit-scrollbar {
    display: none;
}

/* .verify-container {
    height: 100vh;
    overflow: scroll;
} */

.verify-container::-webkit-scrollbar {
    display: none;
}

.resend-otp {
    cursor: pointer;
}

.resend-otp:hover {
    color: black;
}

/* .recoverpw-container {
    height: 100vh;
    overflow: scroll;
} */

.recoverpw-container::-webkit-scrollbar {
    display: none;
}

.successMessage {
    width: 70%;
    margin: auto;
}

.create-universe {
    line-height: 3rem;
}

.input-container {
    /* margin-right: 15px; */
}

.dob-Label {
    margin-right: 10px;
}

.header-main {
    /* background-image: linear-gradient(to right , #FC53FF , #3853FF); */
    background-image: linear-gradient(90deg, rgba(252, 83, 255, 1) 0%, rgba(56, 83, 255, 1) 35%);
    width: 100%;
    padding: 10px;
    display: flex;
}

.check-label {
    font-size: 15px;
    margin-top: -1px;
}

.Signup-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.sign-up {
    width: 60%;
    background-color: #3853FF;
    border: 1px solid #3853FF;
    color: white;
    font-weight: 600;
    margin: auto;
}

.signup-agreement {
    font-size: 13px;
    max-width: 420px;
    margin: auto;
    color: black;
    line-height: 1rem;
    margin-bottom: 12px;
}

.signup-links {
    color: #3853FF;
}

.login-btn {
    background-color: white;
    color: #3853FF;
    border: 1px solid white;
    padding: 2px 30px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin: 5px auto 0 auto;
}

.input-container-header {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px auto 0 auto;
}

.input-container-header input {
    margin-top: 5px;
    width: 245px;
    border-color: whitesmoke;
    background: transparent;
}

.input-container-header input[type=password] {
    width: 160px;
}

.input-container-header input::placeholder {
    color: whitesmoke;
    padding-left: 5px;
}

.confirmation {
    width: 70%;
    display: flex;
    justify-content: flex-end;
}


/* .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2px 2px 0 2px;
} */

.actions input {
    margin-right: 5px;
    margin-top: -1px;
}

.forgot-password {
    cursor: pointer;
}

.action-inner {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.submit-button {
    color: white;
    background-color: #3853FF;
    border: 1px solid white;
    padding: 5px 30px;
    font-weight: 600;
}

.name-inputs {
    display: block
}

.input-container input {
    width: 100%;
}

.input-container2 input {
    width: 100%;
}

.alert-message {
    /* background-image:  linear-gradient(to right , #FC53FF , #3853FF); */
    background-image: linear-gradient(90deg, rgba(252, 83, 255, 1) 0%, rgba(56, 83, 255, 1) 35%);
    color: white;
    font-weight: bold;
    padding: 15px;
    text-align: center;
    margin: 0 auto;
    width: fit-content;
    white-space: nowrap;
    z-index: 1;
}

@media screen and (min-width: 768px) {
    .input-container input {
        width: 220px;
    }
    .input-container2 input {
        width: 220px;
    }
    .error-message {
        position: absolute;
        width: 50%;
        margin: auto;
    }
    .input-container-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 0;
    }
    /* .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    } */
    .forgot-password {
        cursor: pointer;
        margin-left: 10px;
        margin-right: 10px;
    }
    .input-container-header input {
        margin-left: 10px;
        margin-right: 10px;
    }
    .confirm-mail-main {
        display: flex;
        align-items: center;
    }
    .name-inputs {
        display: flex;
        justify-content: space-between;
    }
    .verify-container {
        display: flex;
        align-items: center;
    }
}

@media screen and (min-width: 992px) {
    .input-container-header input {
        width: 250px;
    }
    .input-container-header input[type=password] {
        width: 250px;
    }
}