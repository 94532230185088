.dark .storybar-main-sticky {
  background: inherit;
}
.storybar-main-sticky {
  background: #fff;
  padding-left: 16px;
  height: calc(100vh - 75px);
  overflow-y: auto;
  width: 280px;
  position: fixed;
  top: 75px;
  left: 0;
  z-index: 99 !important;
}

.view-story-tracker {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 75px);
  background-color: #111;
}

.isActiveStory {
  background-color: #ddd;
}

.story-viewer-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
  .story-wrapper {
    padding-left: 300px;
  }
}

@media (min-width: 1400px) {
}
