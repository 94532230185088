.story-upload-main-canvas {
  display: flex;
}

.story-upload-main-canvas-container {
  width: 100%;
  min-height: calc(100vh - 100px);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.story-upload-main-canvas-container:hover {
  cursor: pointer;
}

.upload-story-image {
  height: 310px;
  padding: 16px;
  border-radius: 15px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.upload-personal-story-image {
  background: linear-gradient(-80deg, #695bf0, #a145cb, #fa53ff);
  border: 1px solid #ddd;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add box shadow here */
}

.upload-universe-story-image {
  background: linear-gradient(80deg, #695bf0, #a145cb, #fa53ff);
  border: 1px solid #ddd;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add box shadow here */
}

.upload-story-icon {
  padding: 10px;
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1c1d21;
  margin-bottom: 10px;
  font-size: 18px;
}

.upload-story-image p {
  font-weight: 600;
}

/* Responsive breakpoints */
/* Small devices (phones) */
@media (max-width: 576px) {
  /* Styles for small devices go here */
}

/* Medium devices (tablets) */
@media (max-width: 768px) {
  /* Styles for medium devices go here */
  .story-upload-main-canvas-container {
    padding-left: 0;
  }
}

/* Large devices (desktops) */
@media (max-width: 992px) {
  /* Styles for large devices go here */
}

/* Extra large devices (large desktops) */
@media (max-width: 1200px) {
  /* Styles for extra large devices go here */
}
