$xxs: 380px;
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

:root {
  --comment-background: #f0f2f5;
  --black: #000000;
  --themeMain: #3954ff;
  // --bs-danger: #dc3545 ;
  // --bs-primary-rgb: 51, 76, 230
}

// .bg-soft-primary {
//     background-color: rgba(51, 76, 230, 0.2) !important;
// }

.btn {
  font-weight: 500;
}

.fw-500 {
  font-weight: 500;
}

// .btn-danger,
// .btn-outline-danger {
//     color: #ffffff;
//     background-color: var(--bs-danger);
//     border-color: var(--bs-danger);
//     font-weight: 500;
//     &:hover, &:focus, &.active, &:active {
//         background-color: #c3202f;
//         border-color: var(--bs-danger);
//         color: #ffffff;
//     }
// }

// .btn-outline-danger {
//     color: var(--bs-danger);
//     background-color: transparent;
//     &:hover, &:focus, &.active, &:active {
//         background-color: var(--bs-danger);
//         color: #ffffff;
//     }
// }

// .btn-primary,
// .btn-outline-primary {
//     color: #ffffff;
//     background-color: var(--themeMain);
//     border-color: var(--themeMain);
//     font-weight: 500;
//     &:hover, &:focus, &.active, &:active {
//         background-color: #2b43df;
//         border-color: #2b43df;
//         color: #ffffff;
//     }
// }

// .btn-outline-primary {
//     color: var(--themeMain);
//     background-color: transparent;
//     &:hover, &:focus, &.active, &:active {
//         background-color: var(--themeMain);
//         color: #ffffff;
//     }
// }

.post_cards {
  .dropdown-item {
    &.active,
    &:active {
      i,
      h6 {
        color: #ffffff;
      }
    }
  }
}

.dropdown-item.drop_btn {
  background: transparent !important;
}

.profile_left .card {
  li .text-link {
    color: #777d74;
  }
  .card-header {
    padding: 0.625rem;
    h4.card-title {
      font-size: 1.05rem;
    }
  }
  .card-body {
    padding: 0.625rem;
  }
}

.photo_video_card_wrap {
  flex-wrap: wrap;
  .photo_video_card {
    max-width: calc(50% - 0.625rem);
    margin-bottom: 10px;
    @media (min-width: $md) {
      max-width: calc(33.33% - 0.625rem);
    }
    @media (min-width: $xl) {
      max-width: calc(25% - 0.625rem);
    }
    .user-images {
      height: 100%;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
        rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
      a,
      img {
        height: 100%;
      }
    }
  }
}

.avatar-40 {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  &img {
    object-fit: cover;
  }
}

.avatar-50 {
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  &img {
    object-fit: cover;
  }
}

.avatar-60 {
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  &img {
    object-fit: cover;
  }
}

.avatar-70 {
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  &img {
    object-fit: cover;
  }
}

.min-w-150 {
  min-width: 150px;
}

.br-8 {
  border-radius: 8px;
}

img {
  object-fit: cover;
}

.max-w-250 {
  max-width: 250px;
}

.max-w-300 {
  max-width: 300px;
}

.container {
  @media (min-width: $sm) and (max-width: ($md - 1px)) {
    max-width: 96%;
  }
}

.text-link {
  color: #777d74;
  &:hover {
    color: var(--themeMain) !important;
  }
}

.content-page {
  @media (max-width: ($xs - 1px)) {
    padding: 1rem 0.5rem 0;
  }
}

.create_post_btn {
  .icon {
    position: absolute;
    left: 20px;
    top: calc(50% - 5px);
    display: flex;
    justify-content: center;
    align-items: center;
    &:last-child {
      left: unset;
      right: 20px;
    }
  }
  &.carousel-button-group-profile {
    .icon {
      top: 30%;
    }
  }
}

.bg-soft-danger {
  border-color: #e68c7c !important;
}

.dropdown-menu {
  box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px 0px;
}

.icon {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 100%;
  padding: 1px;
}

.story-img {
  border: 3px solid #b7b6b6;
  border-radius: 50%;
  padding: 2px;
  width: 100px;
  height: 100px;
}

.isSubscribed .story-img {
  border-color: var(--bs-success);
}

.story-img-active {
  border: 3px solid var(--themeMain);
  border-radius: 50%;
  padding: 2px;
  width: 100px;
  height: 100px;
}

.react-multi-carousel-track {
  right: 100px;
  left: 0;
  margin: 0 10px;
}

.react-multi-carousel-track-profile {
  right: 40px;
  margin: 0 10px;
}

.countries {
  margin-top: 3px;
}

.universes-text {
  font-size: 14px;
  color: var(--black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90px;
  margin: auto;
  font-weight: 500;
  // @media (max-width: ($sm - 1px)) {
  //     width: 80px;
  // }
}

.mulitiverse-dropdown {
  width: 95% !important;
}

.btnGrp3 {
  display: flex;
  position: absolute;
  justify-content: space-between;
  top: 310px;
}

.no_pointer_events {
  pointer-events: none;
}

.create_post {
  padding: 10px 8px 10px 5px;
  .carousel-container {
    padding: 0;
    .story-img,
    .story-img-active {
      min-height: 100px;
      min-width: 100px;
      max-width: 100px;
      max-height: 100px;
      margin: auto;
      @media (max-width: ($sm - 1px)) {
        min-height: 80px;
        min-width: 80px;
        max-width: 80px;
        max-height: 80px;
      }
    }
    .uni_image_wrap {
      span {
        display: flex;
        color: #ffffff;
        font-weight: 500;
        padding: 5px;
        font-size: 2rem;
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: center;
        align-items: center;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: linear-gradient(
            90deg,
            #fc53ff 0%,
            #3853ff 90%
          ) !important;
          border-radius: 50% !important;
          min-height: 90px;
          min-width: 90px;
          max-width: 90px;
          max-height: 90px;
          @media (max-width: ($sm - 1px)) {
            min-height: 70px;
            min-width: 70px;
            max-width: 70px;
            max-height: 70px;
          }
        }
      }
      img {
        display: none;
      }
      &.image_broken {
        span {
          display: none;
        }
        img {
          display: block;
        }
      }
      &.filter_modal {
        .story-img,
        .story-img-active {
          min-height: 80px;
          min-width: 80px;
          max-width: 80px;
          max-height: 80px;
        }
        span span {
          min-height: 70px;
          min-width: 70px;
          max-width: 70px;
          max-height: 70px;
        }
      }
    }
  }
}

.carousel-container-profile {
  box-shadow: 0 0 1.25rem 0 rgb(0 0 0 / 10%);
  border-radius: 5px;
  padding: 10px 8px 10px 5px;
  margin-bottom: 20px;
  background: #fff;
  width: 100%;
}

textarea.form-control::placeholder,
::placeholder {
  color: var(--black) !important;
  opacity: 1; /* Firefox */
}

textarea.form-control::-ms-input-placeholder,
textarea.form-control:-ms-input-placeholder,
::-ms-input-placeholder,
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--black) !important;
}

.multiverseSelect {
  width: 100%;
}

.profile-img1 {
  width: 150px;
  height: 150px;
  border: 1px solid;
  border-radius: 100%;
}

.upload-icon {
  border: 1px solid white;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  i {
    font-size: 1rem;
  }
  label,
  a {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: 0;
    line-height: 1 !important;
    cursor: pointer;
  }
}

.user-img {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  line-height: unset;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.avatar-60 {
    width: 60px;
    height: 60px;
    min-width: 60px;
  }
}

.create_post_step {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.3s linear, opacity 0.3s linear;
  transform: translateX(100%) translateZ(1px);
  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%) translateZ(1px);
  }
}

.multiverse_list {
  max-height: 70vh;
  overflow-y: auto;
  .accordion {
    .accordion-item {
      overflow: hidden;
      border-radius: 0;
    }
    .accordion-header {
      .accordion-button {
        padding: 1rem 0;
        color: #3f414d;
        font-size: 1rem;
        font-weight: 500;
      }
    }
    .accordion-body {
      padding: 0 0;
      p {
        margin-bottom: 0.5rem;
      }
    }
  }
  &_filter {
    .badge_wrap {
      // justify-content: center;
      align-items: center;
      gap: 8px !important;
      flex-wrap: wrap;
      max-height: 100px;
      overflow-y: auto;
      .name_badge {
        border: 1px solid #ebebeb;
        padding: 5px 8px;
        line-height: 1;
        border-radius: 8px;
        font-size: 14px;
        white-space: nowrap;
        cursor: pointer;
        &.active {
          background: var(--themeMain);
          border-color: var(--themeMain);
          color: #ffffff;
        }
      }
    }
  }
}

.check_badge {
  padding-left: 0 !important;
  width: 100%;
  margin: 0;
  label {
    background: #ffffff;
    width: 100%;
    padding: 6px 10px;
    color: #777d74;
  }
  input {
    display: none;
    &:checked ~ label {
      background: #f3f3f3;
      color: #3f414d;
    }
  }
}

.post-icon {
  align-items: center;
  color: #3f414d;
  i {
    font-size: 24px;
    line-height: 1;
    // color: #3f414d;
  }
}

// react tags
// .ReactTags__tags {
//     position: relative;
// }
// .ReactTags__clearAll {
//     cursor: pointer;
//     padding: 10px;
//     margin: 10px;
//     background: #f88d8d;
//     color: #fff;
//     border: none;
// }
// .ReactTags__tagInput {
//     border-radius: 2px;
//     display: inline-block;
//     width: 100%;
// }
// .ReactTags__tagInput input.ReactTags__tagInputField,
// .ReactTags__tagInput input.ReactTags__tagInputField:focus {
//     height: 46px;
//     margin: 0;
//     font-size: 12px;
//     border: 1px solid #eee;
//     min-width: 150px;
//     padding: 0 10px;
//     width: 100%;
//     border-radius: 6px;
// }
// .ReactTags__editInput {
//     border-radius: 1px;
// }
// .ReactTags__editTagInput {
//     display: inline-flex;
// }
// .ReactTags__selected span.ReactTags__tag {
//     border: 1px solid #ddd;
//     background: #63bcfd;
//     color: white;
//     font-size: 12px;
//     display: inline-block;
//     padding: 1px 5px;
//     margin: 0 5px 8px 0;
//     border-radius: 4px;
// }
// .ReactTags__selected a.ReactTags__remove {
//     color: #aaa;
//     margin-left: 5px;
//     cursor: pointer;
// }
// .ReactTags__suggestions {
//     position: absolute;
// }
// .ReactTags__suggestions ul {
//     list-style-type: none;
//     box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
//     background: white;
//     width: 200px;
// }
// .ReactTags__suggestions li {
//     border-bottom: 1px solid #ddd;
//     padding: 5px 10px;
//     margin: 0;
// }
// .ReactTags__suggestions li mark {
//     text-decoration: underline;
//     background: none;
//     font-weight: 600;
// }
// .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
//     background: #b7cfe0;
//     cursor: pointer;
// }
// .ReactTags__remove {
//     border: none;
//     cursor: pointer;
//     background: none;
//     color: white;
// }
.friend_list {
  max-height: 70vh;
  overflow-y: auto;
  .friend_item {
    margin-bottom: 10px;
    cursor: pointer;
    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.tagged_friend_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .friend_item {
    cursor: pointer;
    display: inline-flex;
    width: fit-content;
    margin: 0 6px 8px 0;
    align-items: center;
    padding: 8px 28px 8px 8px;
    background: #f3f3f3;
    border-radius: 4px;
    position: relative;
    a {
      border: none;
      cursor: pointer;
      background: none;
      color: #3f414d;
      display: flex;
      position: absolute;
      right: 6px;
      top: 9px;
    }
    &:hover {
      a {
        color: var(--themeMain);
      }
    }
  }
}

.close_btn {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #3f414d;
  &:hover {
    color: var(--themeMain);
  }
  &.border {
    border: 1px solid #e4e6eb !important;
  }
  &.right_10 {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  &.close_sm {
    width: 25px;
    height: 25px;
  }
}

.upload_file_wrap {
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  position: relative;
  padding: 5px;
  min-height: 250px;
  @media (max-width: ($xs - 1px)) {
    min-height: 190px;
  }
  .upload_input {
    z-index: 1;
    .form-label {
      position: absolute;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      background: #f7f8fa;
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .upload_file_inner {
        i {
          font-size: 1.8rem;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #e4e6eb;
          border-radius: 50%;
        }
      }
    }
  }
  .photo_preview {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: relative;
    border-radius: 8px;
    display: flex;
    overflow: hidden;
    max-height: 240px;
    @media (max-width: ($xs - 1px)) {
      max-height: 180px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &.file_uploaded {
    position: absolute;
    left: 0;
    opacity: 0;
    z-index: -1;
  }
}

.video_player_wrap {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  position: relative;
  padding: 5px;
  .video-react {
    background: transparent;
    .video-react-poster,
    &.video-react-fluid,
    &.video-react-16-9 &.video-react-4-3,
    .video-react-video {
      border-radius: 8px;
    }
  }
  .close_btn {
    border-color: #ffffff;
    color: #ffffff;
    right: 15px;
    top: 15px;
    background: rgba(0, 0, 0, 0.3);
    &:hover {
      background: var(--themeMain);
    }
  }
  .video-react-has-started .video-react-control-bar {
    border-radius: 0 0 8px 8px;
  }
}

a .ri-close-fill {
  margin-top: 1px;
}

.badge_btns {
  .btn {
    padding: 0px 8px 0px 5px;
    display: flex;
    align-items: center;
    height: 28px;
    margin-bottom: 10px;
    i {
      margin-right: 4px;
    }
    &.custom_badge {
      cursor: auto;
      background: #dee2e6 !important;
      i {
        font-size: 1rem;
      }
    }
  }
}

.hmb-1 {
  margin-bottom: 3px !important;
}

.bg-trans {
  background: transparent !important;
}

.header_logo {
  max-width: 200px;
  display: flex;
  align-items: center;
  @media (max-width: $xs) {
    max-width: 160px;
  }
  img {
    width: 100%;
    object-fit: contain;
  }
}

.have_account {
  @media (max-width: $xxs) {
    display: none;
  }
}

form {
  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

.login_inputs {
  input {
    background: transparent;
    padding: 4px 5px;
    border-radius: 5px;
    border: 1px solid #ffffff;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    color: #ffffff;
    &::placeholder {
      color: #ffffff !important;
      opacity: 1; /* Firefox */
    }
    &::-ms-input-placeholder,
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #ffffff !important;
    }
  }
  > div {
    padding: 0 5px;
  }
  button {
    display: flex;
    align-items: center;
    height: 35.19px;
    margin-top: 0 !important;
    white-space: nowrap;
  }
}

.auth_page {
  min-height: calc(100vh - 116px);
}

.select_univer_wrap {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  padding-bottom: 20px;
  .universe_card {
    overflow: hidden;
    max-width: 50%;
    max-height: 140px;

    .textDiv {
      position: absolute !important;
      top: 0.3125rem;
      left: 0.3125rem;
      right: 0.3125rem;
      bottom: 0.3125rem;
      background: rgba(0, 0, 0, 0.3);
      padding: 0.5rem;
      display: flex;
      align-items: flex-end;
      border-radius: 8px;
    }
    &.isActive {
      .textDiv {
        box-shadow: rgba(43, 134, 237, 0.9) 0px 0px 0px 2px;
      }
    }
  }
}

.clickable {
  cursor: pointer;
  &.hoverable {
    &:hover {
      color: var(--themeMain) !important;
      * {
        color: var(--themeMain) !important;
      }
    }
  }
}

.notClickable {
  cursor: text !important;
}

.main_loader {
  // position: fixed;
  // top: 40%;
  // left: calc(50% - 37.5px);
  // z-index: 1000;
  // object-fit: cover;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  object-fit: cover;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-control:focus {
  color: var(--black);
}

.post_cards {
  .post-comments {
    .user-img {
      width: 40px;
      height: 40px;
      min-width: 40px;
    }
    .comment-text {
      background: var(--comment-background);
      padding: 8px 10px;
      width: fit-content;
      border-radius: 8px;
      h6 {
        font-weight: 500;
        color: var(--black);
      }
      p {
        line-height: 1.3;
        color: var(--black);
        word-break: break-word;
      }
      .btn-link {
        color: var(--black);
        font-weight: 500;
        font-size: 13px;
        &:hover {
          color: var(--themeMain);
        }
      }
    }
    .comment-activity > a {
      color: #777d74;
      font-size: 13px;
      font-weight: 500;
      &:hover {
        color: var(--themeMain);
      }
    }
  }
  .total-like-block {
    .dropdown > .dropdown-menu > a {
      padding: 0.5rem 0.625rem;
      border-bottom: 1px solid #e1e1e124;
    }
  }
}

.like-block.not_hover span:hover {
  color: #777d74;
  cursor: text;
}

.read_less {
  display: block;
}

.read_more {
  display: none;
}

.read_less_active {
  p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  a.btn-link {
    color: var(--var(--black));
    font-weight: 500;
    &:hover {
      color: var(--themeMain);
    }
  }
  .read_less {
    display: none;
  }
  .read_more {
    display: block;
  }
}

.iq-sidebar-menu .iq-menu li {
  a {
    padding: 0.938rem 0.8rem;
    i {
      margin-right: 0.8rem;
      font-size: 1.5rem;
      color: var(--black);
    }
    &:hover {
      i {
        color: var(--themeMain);
      }
    }
  }
  &.active {
    a i {
      color: var(--themeMain);
    }
  }
}

.primary_drop_btn {
  button {
    border-radius: 8px;
    font-weight: 600;
    color: var(--themeMain) !important;
    background: rgba(var(--bs-primary-rgb), 0.2) !important;
    border-color: rgba(var(--bs-primary-rgb), 0.2) !important;
    &:hover,
    &:focus {
      color: #ffffff !important;
      background: var(--themeMain) !important;
      border-color: var(--themeMain) !important;
    }
  }
  &.friend_btn {
    button {
      font-weight: normal !important;
      font-size: 16px;
    }
    .dropdown-menu {
      .dropdown-item {
        background: #ffffff !important;
        font-size: 14px;
        color: var(--black);
        font-weight: 500;
        padding: 5px 10px;
        i {
          font-size: 16px;
        }
        svg {
          width: 16px;
        }
        &:hover {
          color: var(--themeMain);
        }
        &.danger {
          &:hover {
            color: var(--bs-danger) !important;
          }
        }
      }
    }
  }
}

.friend_btn {
  button {
    height: 38px;
  }
  .btn-soft-light {
    border-color: #ebebeb;
  }
}

.btn-soft-light.border-dark {
  border-color: #ebebeb !important;
}

.video_players {
  .video-js {
    width: 100%;
    height: 350px;
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  overflow: hidden;
}

.flex-1 {
  flex: 1;
}

.iq-top-navbar {
  background-image: linear-gradient(
    90deg,
    rgba(252, 83, 255, 1) 0%,
    rgba(56, 83, 255, 1) 35%
  ) !important;
  // background-image: linear-gradient(to right, #FC53FF, #3853FF);
  min-height: 75px;
  .navbar-collapse {
    margin-top: 0;
    .navbar-list {
      li > {
        a {
          color: #ffffff;
          opacity: 1;
          &:hover {
            color: #ffffff !important;
            opacity: 1;
          }
          > img {
            object-fit: cover;
            margin-right: 5px;
          }
          &.search-toggle {
            opacity: 1;
          }
          @media (max-width: $lg) {
            color: var(--black);
            &:hover {
              color: var(--black) !important;
            }
          }
        }
      }

      li {
        .sub-drop {
          .iq-sub-card {
            &.dropdown-item,
            .dropdown-item {
              padding: 0.6rem 1rem;
              .card-icon {
                min-width: 2.813rem;
              }
              p {
                white-space: normal;
              }
            }
          }
          .dropdown-item.btn-link {
            background: transparent !important;
          }
        }
      }
    }
  }
  .caption h6 {
    color: #ffffff;
  }
  .searchbox {
    display: flex;
    align-items: center;
    .search-link {
      top: unset;
      font-size: 1.4rem;
      line-height: 1;
      display: flex;
      color: #ffffff;
    }
    input {
      background: transparent;
      color: #ffffff !important;
      opacity: 1;
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #ffffff !important;
        opacity: 1;
        /* Firefox */
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #ffffff !important;
      }
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #ffffff !important;
      }
    }
  }
  .iq-navbar-logo a {
    align-items: center;
  }
  .navbar-toggler {
    @media (max-width: $lg) {
      color: #ffffff;
    }
  }
  .wrapper-menu {
    color: #ffffff;
  }
  .sub-drop {
    @media (max-width: $xs) {
      width: calc(100vw - 20px);
      max-width: 420px;
    }
    .iq-friend-request {
      .iq-sub-card {
        @media (max-width: $lg) {
          display: flex !important;
        }
      }
    }
  }
}

.mobile_logo {
  width: 45px;
  height: 45px;
  display: flex;
  position: relative;
  &:after {
    content: "";
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 50%;
    padding: initial;
    position: absolute;
    z-index: -1;
    left: 2.5px;
    top: 2.5px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.web_logo {
  max-width: 200px;
  img {
    object-fit: contain;
    height: unset;
  }
  @media (max-width: $lg) {
    max-width: 200px;
  }
  @media (max-width: $xs) {
    max-width: 180px;
  }
}

.alert-message {
  // background-image:  linear-gradient(to right , #FC53FF , #3853FF);
  background-image: linear-gradient(
    90deg,
    rgba(252, 83, 255, 1) 0%,
    rgba(56, 83, 255, 1) 35%
  );
  position: fixed;
  top: 80px;
  width: fit-content;
  padding: 15px 4vw;
  z-index: 99999;
}

ul.header-nav li {
  z-index: 2;
}

.profile-header {
  .user-detail {
    z-index: 1;
  }
  &.top_banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    .user-detail {
      left: unset;
      right: unset;
      .profile-img.image-main {
        margin: 0;
      }
    }
  }
}

.sign-in-detail {
  height: auto;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: fixed;
  top: 75px;
  right: 10px;
  &.sub-drop {
    .card .card-body {
      max-height: calc(100vh - 180px);
      overflow-y: auto;
    }
  }
}

.iq-float-menu {
  z-index: 999;
}

.container-md,
.container-sm,
.container {
  @media (max-width: $lg) {
    max-width: 100%;
  }
}

.profile-img.image-main {
  width: 120px;
  height: 120px;
  margin: auto;
  .p-image {
    right: 4px;
    bottom: 4px;
  }
}

.content-page {
  min-height: calc(100vh - 130px);
  @media (max-width: $xs) {
    min-height: calc(100vh - 152px);
  }
  // @media (max-width: $lg) {
  //     max-height: calc(100vh - 152px);
  // }
  .no_content {
    min-height: calc(100vh - 240px);
  }
}

.edit-universe-page {
  .content-page {
    min-height: unset;
    height: calc(100vh - 130px);
    @media (max-width: $xs) {
      height: calc(100vh - 160px);
    }
  }
  .select_univer_wrap {
    max-height: calc(100vh - 325px);
    @media (max-width: $xs) {
      max-height: calc(100vh - 345px);
    }
  }
}

.text-ellipsis-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-ellipsis-2 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.text-ellipsis-3 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.user_rqst_details {
  max-width: calc(100% - 140px);
  &.dropdown-item {
    padding: 0 !important;
    background: transparent !important;
  }
  &.people_list {
    max-width: calc(100% - 130px);
  }
  &.sent_rqst_list {
    max-width: calc(100% - 80px);
  }
  .user_detail {
    max-width: calc(100vh - 80px);
    @media (max-width: $xs) {
      max-width: calc(100% - 60px);
    }
  }
}

.apps-icon {
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  img {
    width: 100% !important;
  }
}

.banner_img {
  // max-height: calc(15vh + 15vw);
  // min-height: 150px;
  height: calc(9vh + 27vw);
  display: flex;
  a {
    width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.docs-paras {
  p {
    font-size: 15px;
    line-height: 1.5;
  }
}

.bg-soft-danger:hover {
  color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.iq-friendlist-block {
  &.custom_friends {
    .img-fluid {
      width: 100px;
      min-width: 100px;
      min-height: 100px;
      @media (min-width: $md) and (max-width: ($lg - 1px)) {
        width: 90px;
        min-width: 90px;
        min-height: 90px;
      }
      @media (max-width: ($sm - 1px)) {
        max-width: 80px;
        min-width: 80px;
        min-height: 80px;
      }
    }
    .friend_wrap {
      width: calc(100% - 110px);
      &.full_w {
        width: 100%;
      }
      .friend-info {
        width: calc(100% - 110px);
        @media (min-width: $md) and (max-width: ($lg - 1px)) {
          width: calc(100% - 100px);
        }
        @media (max-width: ($sm - 1px)) {
          width: calc(100% - 90px);
        }
        h5,
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }
        h5 {
          font-size: 16px;
        }
      }
    }
  }
}

.custom_padding {
  padding: 0.28rem 0 !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.profile_picture_form {
  .profile-user-wid {
    margin: -50px auto;

    .img-thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.pagination {
  @media screen and (max-width: ($sm - 1px)) {
    --bs-pagination-padding-x: 0.5rem;
    --bs-pagination-padding-y: 0.25rem;
  }
}

.pagination {
  .page-link {
    padding: 4px 6px;
    min-width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

body.ReactModal__Body--open {
  overflow: hidden;
}

.friend_list_card {
  .user_details {
    width: calc(100% - 215px);
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 215px);
    margin-right: auto;
    &.request {
      width: calc(100% - 225px);
      max-width: calc(100% - 225px);
    }
    h6 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.btn.btn-icon {
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  &.small_btn {
    width: 30px;
    height: 30px;
  }
}

ul.request-list {
  .img-fluid {
    .avatar-70 {
      @media (max-width: ($xs - 1px)) {
        height: 50px;
        width: 50px;
        line-height: 50px;
      }
    }
  }
}

.fcc {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.about_title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}

.about_items {
  display: flex;
  margin-bottom: 10px;
  .icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    svg {
      width: 30px;
    }
  }
  h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.3;
    // text-transform: capitalize;
    &.text-link {
      color: #3f414d;
    }
  }
  span {
    line-height: 1.3;
    font-size: 13px;
  }
}

.friend_about {
  .about_item_wrap {
    &:not(:last-child) {
      padding-bottom: 10px;
      margin-bottom: 15px;
    }
    &:last-child {
      border-bottom: none !important;
    }
  }
}

.work_edu_list {
  .work_edu_right {
    width: 100%;
    max-width: calc(100% - 40px);
    .ms-2.ps-sm-1 {
      width: calc(100% - 80px);
    }
  }
}

.notify_unread {
  background: #f9f9f9;
}

.count_dot_wrap {
  width: 20px;
  height: 20px;
  line-height: 1;
  .count_dot {
    position: absolute;
    width: 15px;
    height: 15px;
    background: rgb(209, 10, 10);
    color: #ffffff !important;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 11px;
    line-height: 15px;
    right: -5px;
    top: -5px;
    font-weight: 600;
  }
}

.chat_page {
  .chat_users {
    padding: 5px;
    border-radius: 8px;
    border: 1px solid #f3f3f3;
    margin-bottom: 10px;
    width: 100%;
    &.active {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      // background: rgba(57, 84, 255, .1);
      border-color: #3954ff;
    }
  }
  .chat_time {
    font-size: 0.7rem;
  }
  .chat-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    .react-emoji {
      width: calc(100% - 80px);
    }
    .react-input-emoji--container {
      margin: 0;
      border-radius: 8px !important;
      border-color: #ced4da !important;
      .react-input-emoji--button {
        padding: 0 6px 0 10px;
      }
    }
    ::placeholder {
      color: #a0a0a0 !important;
    }
    .react-input-emoji--input.form-contro {
      line-height: 20px;
      font-size: 15px;
      padding: 10px 12px 10px 15px;
    }
    .react-input-emoji--placeholder {
      letter-spacing: 1px;
      font-size: 15px;
    }
    .react-input-emoji--button {
      margin-bottom: 1px;
    }
  }

  .image_preview {
    padding-top: 5px;
    padding-bottom: 5px;

    &.chat-message {
      background: transparent;
      padding: 0;
    }
    img {
      width: 100%;
      max-width: 180px;
      min-width: 100px;
    }
    .close_btn.right_10 {
      background: #fff;
      right: 6px;
      top: 6px;
      width: 25px;
      height: 25px;
      font-size: 12px;
    }
    &.popup {
      position: absolute;
      bottom: 15px;
      right: 12%;
      background: #fff;
      padding: 10px;
      border-radius: 8px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      img {
        max-width: 320px;
      }
    }
  }
}

.dropdown-toggle.hide_arrow::after {
  display: none !important;
}

.icons_head {
  i {
    font-size: 20px;
  }
}

.data_privacy {
  h5 {
    font-size: 1.2rem;
    @media (max-width: ($md - 1px)) {
      font-size: 1rem;
    }
  }
}

.react-multi-carousel-track {
  margin: 0 2px;
}

.profile-img-gallary {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  li {
    width: calc(33.33% - 3.33px);
    margin-bottom: 10px;

    @media (min-width: $md) {
      width: calc(50% - 2.5px);
    }

    @media (min-width: $lg) {
      width: calc(33.33% - 3.33px);
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;

    a {
      height: 100%;
      max-height: 120px;
      width: 100%;

      @media (min-width: $sm) {
        max-height: 100px;
      }

      @media (min-width: $md) {
        max-height: 120px;
      }

      @media (min-width: $lg) {
        max-height: 100px;
      }
    }
    img {
      border-radius: 4px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.audio_thumb_box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #efebeb;
  background: #ebebeb;
  border-radius: 5px;
  height: 100%;
  img {
    max-width: 100px;
  }
}

.radio_align {
  margin-top: 2px;
}

.custom_share_modal {
  background: transparent;
  width: 100%;
  margin: 0;
  max-width: unset;
  display: flex;
  flex: 1 0 auto;
  min-height: 100%;
  box-shadow: none;
  .modal-dialog {
    width: 100%;
  }
  &.media-share-modal {
    &.media-share-modal {
      .card {
        min-height: unset;
        input {
          cursor: pointer;
        }
        .Demo__some-network {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 5px;
          div {
            display: none;
          }
          button {
            &:hover {
              transform: scale(1.1);
            }
            svg {
              width: 45px;
              height: 45px;
              @media (max-width: ($xl - 1px)) {
                width: 40px;
                height: 40px;
              }
              @media (max-width: ($md - 1px)) {
                width: 30px;
                height: 30px;
              }
            }
          }
        }
        input {
          padding: 0.5rem;
        }
      }
    }
  }
}

.text-dark {
  color: #3f414d !important;
}

// dark mode

.dark {
  textarea.form-control::placeholder,
  ::placeholder {
    color: #8c91b6 !important;
    opacity: 1; /* Firefox */
  }

  .universe_card {
    p {
      color: #ffffff;
    }
  }

  textarea.form-control::-ms-input-placeholder,
  textarea.form-control:-ms-input-placeholder,
  ::-ms-input-placeholder,
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #8c91b6 !important;
  }

  .iq-sidebar-menu .iq-menu li a {
    i {
      color: #8c91b6;
    }
    &:hover {
      i {
        color: var(--themeMain);
      }
    }
  }

  .iq-edit-profile .nav-link,
  .post-icon,
  .universes-text {
    color: #8c91b6;
  }

  .iq-top-navbar .navbar-collapse {
    background-color: transparent;

    @media (max-width: 991.98px) {
      background-color: #1e2745;
    }

    .navbar-list li > a {
      color: #ffffff;
      &:hover {
        color: var(----themeMain) !important;
      }
    }
  }

  .iq-search-bar .search-input {
    background: transparent;
    border-color: #ffffff;
  }

  .text-dark,
  .text-black,
  p.text-black {
    color: #8c91b6 !important;
  }

  .bg-light {
    .text-black,
    &.text-black {
      color: #000000 !important;
    }
  }

  .multiverse_list .accordion .accordion-header .accordion-button,
  .form-check-label,
  p {
    color: #8c91b6;
  }

  .multiverse_list .check_badge label,
  .tagged_friend_list .friend_item,
  .upload_file_wrap .upload_input .form-label,
  .post_cards .post-comments .comment-text {
    background: rgba(80, 181, 255, 0.1) !important;
    h6,
    a,
    p,
    span,
    i {
      color: #ffffff !important;
    }
    a.btn-link {
      color: #cecdcd !important;
      &:hover {
        color: var(----themeMain) !important;
      }
    }
  }

  .multiverse_list .check_badge input:checked ~ label,
  .form-control:focus {
    color: #ffffff;
  }

  .custom_friends,
  .upload_file_wrap {
    border-color: rgba(80, 181, 255, 0.1) !important;
  }
  .badge_btns .btn {
    &.btn-light {
      background: rgba(80, 181, 255, 0.1) !important;
      border-color: rgba(80, 181, 255, 0.1);
      color: #ffffff;
      a.btn-link {
        color: #a6a6a6;
        &:hover {
          color: var(----themeMain);
        }
      }
    }
  }

  .about_items h6.text-link {
    color: #8c91b6;
  }
  .profile_left {
    .card {
      li .text-link {
        color: #8c91b6;
      }
    }
  }

  .friend_btn {
    .btn-soft-light {
      border-color: rgba(222, 226, 230, 0.1);
    }
  }
  .btn-soft-light.border-dark {
    border-color: rgba(222, 226, 230, 0.1) !important;
  }

  .chat-data {
    background: #161e36 !important;
    .other-user {
      .chat-message p {
        color: #ffffff;
      }
    }
    .react-input-emoji--container {
      background: #161e36 !important;
    }
    .react-input-emoji--container {
      color: #a0a0a0;
    }
    span.iq-start-icon {
      background: #1c284a;
    }
  }
}

ul.post-comments.comment_lists {
  display: flex;
  flex-direction: column-reverse;
}

.card_shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  box-shadow: rgba(57, 84, 255, 1) 0px 0px 0px 1.5px;
}

.search_dropdown {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.938rem;
  top: 75px;
  left: 0;
  height: calc(100vh - 75px);
  background: rgba(0, 0, 0, 0.2);
  line-height: 1.5;
  .card {
    width: 100%;
    max-width: 600px;
    height: fit-content;
    .request-list {
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      margin-right: -10px !important;
      padding-right: 10px !important;
    }
  }
}

.filter_modal .modal-dialog {
  width: 95%;
  max-width: 800px;
}

.notify_settings {
  .form-switch {
    .form-check-input {
      width: 3rem;
      height: 1.5rem;
    }
  }

  p {
    line-height: 1.4;
  }
}

.user-post {
  a {
    display: flex;
    justify-content: center;
    img {
      object-fit: cover;
      object-position: center;
      width: 100% !important;
      max-height: 500px;
    }
  }
}

.extra_page {
  min-height: calc(100vh - 145px);
}

.error_page {
  height: calc(100vh - 150px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-divider {
  span {
    background: #ffffff;
    margin-bottom: 15px;
    display: inline-block;
    padding: 6px 10px;
    border-radius: 25px;
    min-width: 100px;
    line-height: 1.5;
    white-space: nowrap;
    font-weight: 500;
  }
}

.signup_page.container {
  max-width: 1200px;
}

.dropdown .dropdown-menu {
  border-radius: 8px !important;
  overflow: hidden;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  // box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
}

.lh-1-4 {
  line-height: 1.4;
}
