.dark .suggested-friends-main-sticky {
  background: inherit;
}
.suggested-friends-main-sticky {
  background: #fff;
  height: calc(100vh - 75px);
  overflow-y: auto;
  width: 280px;
  position: fixed;
  top: 75px;
  right: 0;
  z-index: 99 !important;
}
